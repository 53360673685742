.scroll {
  scrollbar-width: thin;
  scrollbar-color: #67725d #67725d;
}

/* Works on Chrome, Edge, and Safari */
.scroll::-webkit-scrollbar {
  width: 8px;
}

.scroll::-webkit-scrollbar-track {
  background: #ffffff;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #67725d;
  border-radius: 20px;
  border: 2px solid #ffffff
}


.video-container {
  height: 480px;
  width: 100%;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.video-container .caption {
  z-index: 1;
  position: relative;
  text-align: center;
}

.un {
  display: inline-block;
}

.un::after {
  content: '';
  width: 0px;
  height: 1.5px;
  display: block;
  background: #ffffff;
  transition: 500ms;
}

.un:hover::after {
  width: 100%;
}

.unActive {
  border-bottom: 1.5px solid #ffffff;
}